<template>
  <div class="content-box">
    <div class="content-main">
      <div class="content-main-left">
        <sidebar @tab-outer="handleTabOuter" @tab-inner="handleTabInner"/>
      </div>
      <div class="content-main-right">
        <exports-to-china v-if="current==='PorkExportsToChina'" :data="data" />
        <global-pork-exports v-if="current==='GlobalPorkExports'" :data="data" />
        <cattle-slaughter v-if="current==='CattleSlaughterPork'" :data="data" />
        <data-pork-yield v-if="current==='PorkDataYield'" :data="data" />
        <data-hongkong v-if="current==='HongkongPorkImports'" :data="data" />
        <china-pork-imports v-if="current==='ChinasPorkImports'" :data="data" />
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from './components/sidebar'
import ExportsToChina from './data-exports-to-china'
import GlobalPorkExports from './data-global-pork-exports'
import CattleSlaughter from './data-cattle-slaughter'
import DataHongkong from './data-hongkong'
import ChinaPorkImports from './data-china-pork-imports'
import DataPorkYield from './data-pork-yield'

export default {
  name: 'CnBTCData',
  components: {
    Sidebar,
    ExportsToChina,
    GlobalPorkExports,
    CattleSlaughter,
    DataHongkong,
    ChinaPorkImports,
    DataPorkYield
  },
  data () {
    return {
      data: {},
      outer: '',
      current: 'PorkExportsToChina'
    }
  },
  methods: {
    handleTabOuter (item) {
      this.outer = item.value
      if (this.outer === 'PorkExportsToChina') {
        this.current = this.outer
      }
      if (this.outer === 'HongkongPorkImports') {
        this.current = this.outer
      }
      if (this.outer === 'ChinasPorkImports') {
        this.current = this.outer
      }
      // if (this.outer === 'PorkDataYield') {
      //   this.current = this.outer
      // }
    },
    handleTabInner (item) {
      this.data = Object.assign({}, item)
      this.current = this.outer
    }
  }
}
</script>

<style lang="scss" scoped>
.content-box {
  display: flex;
  justify-content: center;
  background-color: #f5f5f5;
}

.content-main {
  width: 1248px;
  display: flex;
}

.content-main-left {
  width: 240px;
  background-color: #ffffff;
}

.content-main-right {
  flex: 1;
}
</style>
