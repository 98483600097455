<template>
  <div class="date-picker-selector-box hand" @click.stop>
    <div style="display: flex; align-items: center;" @click="handleTapPicker">
      <span>{{ fullDate }}</span>
      <div :class="{ 'triangle-up': visible, 'triangle-down': !visible }" class="btc-triangle"></div>
    </div>
    <div v-show="visible" class="pull-down-selector">
      <div class="selector-header">
        <div>
          <div class="item" @click.stop="handleTapPrev">&lt;&lt;</div>
        </div>
        <div>{{ year }}</div>
        <div>
          <div class="item" @click.stop="handleTapNext">&gt;&gt;</div>
        </div>
      </div>
      <div class="selector-body">
        <div v-for="(item, index) in monthArray" :key="index" class="item hand">
          <span :class="{ active: item === month }" @click="handleTapItem(item)">{{ item }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'DateSelector',
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    date: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      year: parseInt(this.date.slice(0, 4)),
      // month: this.date.slice(5, 7),
      // fullDate: this.date,
      yearArray: ['2018', '2019', '2020', '2021'],
      monthArray: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']
    }
  },
  computed: {
    month: {
      get () {
        return this.date.slice(5, 7)
      },
      set (val) {
        this.$emit('update:date', val)
      }
    },
    visible: {
      get () {
        return this.isShow
      },
      set (val) {
        this.$emit('update:isShow', val)
      }
    },
    fullDate: {
      get () {
        return this.date
      },
      set (val) {
        this.$emit('update:date', val)
      }
    }
  },
  mounted () {
    document.body.addEventListener('click', () => {
      this.visible = false
    }, false)
  },
  methods: {
    handleTapPicker () {
      this.$emit('tap-picker')
    },
    handleTapItem (item) {
      this.$emit('tap-item', {
        date: `${this.year}-${item}`,
        month: item
      })
    },
    changeMonth (month) {
      this.month = this.year + '-' + month
    },
    changeDate (month) {
      this.fullDate = `${this.year}-${month}`
      this.visible = false
    },
    handleTapPrev () {
      if (this.year > 2018) {
        this.year -= 1
      }
    },
    handleTapNext () {
      if (this.year < 2021) {
        this.year += 1
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.date-picker-selector-box {
  height: 30px;
  padding: 0 10px;
  border-radius: 15px;
  line-height: 30px;
  border: 1px solid #5f6162;
  position: relative;
  font-size: 12px;
  font-weight: bold;
}
.pull-down-selector {
  min-width: 200px;
  background: #ffffff;
  position: absolute;
  top: 35px;
  right: 0;
  // bottom: 35px;
  left: 0;
  z-index: 1;
  border-radius: 10px;
  box-shadow: 0 0 5px #e0e0e0;
}
.selector-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
}
.selector-header > div {
  display: flex;
}
.selector-header .item {
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  background: #ffffff;
  border-radius: 5px;
  transform: scale(0.7);
  box-shadow: 0 0 5px #e0e0e0;
}
.selector-body {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.selector-body > .item {
  padding: 10px;
}
.selector-body > .item > span {
  display: inline-block;
  padding: 0 6px;
  font-size: 14px;
  transform: scale(0.7);
  border-radius: 5px;
  outline: 1px solid #e0e0e0;
}
.active {
  color: #ffffff;
  background: #014479;
}
</style>
