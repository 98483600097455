<template>
  <div>
    <div class="item-box">
      <btc-chart v-if="chartData" ref="chart" :key="data.value" :data="chartData" :unit="unit" :is-en-expire="isEnExpire" :title="'准入国猪肉产量 -- ' + data.label" @click.native="handleCharts"/>
    </div>
    <div class="item-box" style="padding: 30px;">
      <div class="selector-box">
        <datetime v-if="startDate&&endDate" :key="data.value" :start-date.sync="startDate" :end-date.sync="endDate" @tap-start="handleTapDate($event, 'StartDate')" @tap-end="handleTapDate($event, 'EndDate')"/>
      </div>
      <btc-table-header :data="getHeader()"/>
      <btc-table :data="list">
        <template v-slot="{ row, index }">
          <!-- <btc-table-cell v-if="isPlantSlaughter" :data="row" :index="index">
            <template v-slot="{ cell, index }">
              <span v-if="cell.PorkNewDataID==='总计'">*</span>
              <span v-else>{{ index }}</span>
            </template>
          </btc-table-cell>
          <btc-table-cell v-if="isPlantSlaughter" :data="row">
            <template v-slot="{ cell }">
              <span>{{ cell.PorkNewDataID }}</span>
            </template>
          </btc-table-cell> -->
          <btc-table-cell v-if="isCountrySlaughter" :data="row" :index="index">
            <template v-slot="{ cell }">
              <span>{{ cell.Year }}</span>
            </template>
          </btc-table-cell>
          <btc-table-cell v-if="isCountrySlaughter" :data="row">
            <template v-slot="{ cell }">
              <span>{{ cell.Month }}</span>
            </template>
          </btc-table-cell>
          <btc-table-cell v-if="!isCountrySlaughter" :data="row">
            <template v-slot="{ cell }">
              <span>{{ cell.Origin }}</span>
            </template>
          </btc-table-cell>
          <btc-table-cell :data="row" @click.native="isLoginEnExpire">
            <template v-slot="{ cell }">
              <span>{{ cell.Volume }}</span>
            </template>
          </btc-table-cell>
          <!-- <btc-table-cell v-if="isCountrySlaughter" :data="row" @click.native="isLoginEnExpire">
            <template v-slot="{ cell }">
              <b-form-checkbox v-model="checkbox"
              :value="cell.Date"
              :disabled="(checkbox.length === 1 && checkbox[0] === cell.PorkNewDataID) || !isEnExpire || isLogin"
              plain
              @change="handleChangeCheckbox($event, 'PorkNewDataID', 'GetPorkNewDataFourChart')"/>
            </template>
          </btc-table-cell> -->
          <btc-table-cell v-if="!isCountrySlaughter" :data="row" @click.native="isLoginEnExpire">
            <template v-slot="{ cell }">
              <b-form-checkbox v-model="checkbox2"
              :value="cell.Origin"
              :disabled="(checkbox2.length === 1 && checkbox2[0] === cell.Origin) || !isEnExpire || isLogin"
              plain
              @change="handleChangeCheckbox($event, 'Origin', 'GetWebPorkNewDataFourContrastChart')"/>
            </template>
          </btc-table-cell>
        </template>
      </btc-table>
    </div>
  </div>
</template>

<script>
import BtcTable from './components/table'
import BtcChart from './components/chart'
import Datetime from './components/datepicker'
import BtcTableHeader from './components/table/table-header'
import BtcTableCell from './components/table/table-cell'
import { getLocalStore } from '@/utils/common'
import commonmix from '@/utils/commonmix'

export default {
  mixins: [commonmix],
  name: 'PorkDataYield',
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    BtcTable,
    BtcChart,
    Datetime,
    BtcTableCell,
    BtcTableHeader
  },
  data () {
    return {
      unit: '单位: 万吨',
      list: [],
      selected: [],
      selectLength: 0,
      checkbox2: this.data.value === '以上对比' ? ['美国'] : ['法国'],
      chartData: null,
      chartQuery: {
        UserID: getLocalStore().UserID,
        LanguageCode: 'zh-CN',
        IsFirst: false,
        Origin: '美国',
        isEn: false
      },
      tableQuery: {
        UserID: getLocalStore().UserID,
        LanguageCode: 'zh-CN',
        IsFirst: false,
        Origin: '美国',
        StartDate: '',
        EndDate: '',
        isEn: false
      },
      header2: [
        { label: '年', icon: null },
        { label: '月', icon: null },
        { label: '产量 (万吨)', icon: require('@/assets/image/data/porkslaughter.png') }
      ],
      header3: [
        { label: '国别', icon: require('@/assets/image/data/country.png') },
        { label: '产量 (万吨)', icon: require('@/assets/image/data/porkslaughter.png') },
        { label: '对比选择', icon: require('@/assets/image/data/contrast.png') }
      ],
      startDate: '',
      endDate: '',
      isDisableOrigin: '美国|'
    }
  },
  computed: {
    amount () {
      var num = 0
      this.list.forEach(e => {
        num += parseFloat(e.Volume)
      })
      return num.toFixed(1)
    },
    isCountrySlaughter () {
      return ['西班牙', '美国', '巴西', '德国', '加拿大', '丹麦', '荷兰', '法国', '爱尔兰', '奥地利', '葡萄牙', '芬兰', '意大利'].includes(this.data.value)
    },
    isOther () {
      return this.data.value === '以上对比' ? ['美国'] : ['法国']
    },
    isLogin () {
      return this.$store.getters.token == null || this.$store.getters.token === ''
    },
    isEnExpire () {
      return this.getUserProfile(this.$t('message._btcdata'))
    }
  },
  watch: {
    'data.value' (nval, oval) {
      if (nval !== oval) {
        this.unit = this.isCountrySlaughter ? '单位: 万吨' : '单位: 万吨'
        this.reset()
        this.getData(nval, (data) => {
          this.$refs.chart.refreshData(data)
        })
      }
    }
  },
  mounted () {
    this.getData(this.data.value)
  },
  methods: {
    reset () {
      // if (this.isCountrySlaughter) {
      //   this.checkbox = ['总计']
      //   // this.chartQuery.Date = '总计'
      // }
      if (this.isOther) {
        this.checkbox2 = this.data.value === '以上对比' ? ['美国'] : ['法国']
        this.chartQuery.Origin = '美国'
      }
    },
    getChartData (method, fn, table) {
      this.$store.dispatch('SetLoadingStatus', true)
      this.$store.dispatch(method, this.chartQuery).then(() => {
        this.chartData = this.$store.getters.dataChart
        this.startDate = this.chartData.tableEndDate
        this.endDate = this.chartData.tableEndDate
        // this.tableQuery.StartDate = this.startDate.replace('-', '')
        this.tableQuery.StartDate = this.endDate.replace('-', '')
        this.tableQuery.EndDate = this.endDate.replace('-', '')
        // console.log('this.tableQuery.StartDate' + this.tableQuery.StartDate)
        if (fn) {
          fn(this.chartData)
        }

        if (table) {
          this.getTableData(table)
        }

        this.$store.dispatch('SetLoadingStatus', false)
      }).catch((err) => {
        this.$store.dispatch('SetLoadingStatus', false)
        console.error(err)
      })
    },
    getTableData (method, fn) {
      this.$store.dispatch('SetLoadingStatus', true)
      this.$store.dispatch(method, this.tableQuery).then(() => {
        this.list = this.$store.getters.dataTable.tableData
        if (fn) {
          fn()
        }
        this.$store.dispatch('SetLoadingStatus', false)
      }).catch((err) => {
        console.error(err)
        this.$store.dispatch('SetLoadingStatus', false)
      })
    },
    getData (params, fn) {
      this.chartQuery.Origin = params
      this.tableQuery.Origin = params
      if (['西班牙', '美国', '巴西', '德国', '加拿大', '丹麦', '荷兰', '法国', '爱尔兰', '奥地利', '葡萄牙', '芬兰', '意大利'].includes(params)) {
        this.getChartData('GetWebPorkNewDataFourChart', fn, 'GetWebPorkNewDataFourTable')
      } else {
        this.chartQuery.Origin = '美国'
        this.getChartData('GetWebPorkNewDataFourContrastChart', fn, 'GetWebPorkNewDataFourContrastTable')
      }
    },
    getHeader () {
      if (this.isCountrySlaughter) {
        return this.header2
      } else {
        return this.header3
      }
    },
    handleChangeCheckbox (e, type, method) {
      this.selected = e
      this.selectLength = e.length
      if (this.selectLength === 0) {
        return
      }
      if (this.selectLength > 5) {
        e.shift()
      }
      this.chartQuery.Origin = e.join('|')
      this.getChartData(method, (data) => {
        this.$refs.chart.refreshData(data)
      })
    },
    handleTapDate (date, type) {
      let method = ''
      this.tableQuery[type] = date
      if (this.isCountrySlaughter) {
        method = 'GetWebPorkNewDataFourTable'
      } else {
        method = 'GetWebPorkNewDataFourContrastTable'
      }
      this.getTableData(method)
    },
    handleCharts () {
      if (this.$store.getters.token) {
        if (!this.isEnExpire) {
          this.openCnMembershipModal()
        }
      } else {
        this.openCnLoginModal()
      }
    },
    isLoginEnExpire () {
      if (this.$store.getters.token) {
        if (!this.isEnExpire) {
          this.openCnMembershipModal()
        }
      } else {
        this.openCnLoginModal()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.item-box {
  min-height: 420px;
  margin: 20px;
  padding-top: 20px;
  border-radius: 20px;
  background-color: #ffffff;
}

/* // 筛选 */
.selector-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.selector-left {
  display: flex;
  justify-content: space-between;
}
</style>
