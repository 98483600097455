<template>
  <div>
<!--    `@/assets/image/est/national-flag/${arr[0]}.png`-->
    <div class="item-box">
      <btc-chart v-if="chartData" ref="chart" :key="data.value" :data="chartData" :unit="unit" :is-en-expire="isEnExpire" :title="'准入国猪肉出口数据 -- ' + data.label" @click.native="handleCharts"/>
    </div>
    <div class="item-box" style="padding: 30px;">
      <div class="selector-box">
        <div class="selector-left">
          <selector :key="data.value+'1'" :items="type"  @tab-item="handleTabType"/>
          <selector v-show="data.value!=='美国'" :key="data.value+'2'" :items="meatType"  :disabled="isDisable" @tab-item="handleTabMeatType"/>
          <selector v-show="data.value!=='美国'" :key="data.value+'3'" :items="storage"  :disabled="isDisable" @tab-item="handleTabStorage"/>
        </div>
        <datetime v-if="startDate&&endDate" :key="data.value" :start-date.sync="startDate" :end-date.sync="endDate" @tap-start="handleTapDate($event, 'StartDate')" @tap-end="handleTapDate($event, 'EndDate')"/>
      </div>
      <btc-table-header :data="header"/>
      <btc-table :data="list">
        <template v-slot="{ row, index }">
          <btc-table-cell :data="row" :index="index">
            <template v-slot="{ cell, index }">
              <span v-if="cell.Destination==='总计'">*</span>
              <span v-else>{{ index }}</span>
            </template>
          </btc-table-cell>
          <btc-table-cell :data="row">
            <template v-slot="{ cell }">
              <span>{{ cell.Destination }}</span>
            </template>
          </btc-table-cell>
          <btc-table-cell :data="row" @click.native="isLoginEnExpire">
            <template v-slot="{ cell }">
              <span>{{ cell.Volume }}</span>
            </template>
          </btc-table-cell>
          <btc-table-cell :data="row" @click.native="isLoginEnExpire">
            <template v-slot="{ cell }">
              <b-form-checkbox v-model="checkbox"
              :value="cell.Destination"
              :disabled="(checkbox.length === 1 && checkbox[0] === cell.Destination) || !isEnExpire || isLogin"
              plain
              @change="handleChangeCheckbox"/>
            </template>
          </btc-table-cell>
        </template>
      </btc-table>
    </div>
  </div>
</template>

<script>
import BtcTable from './components/table'
import BtcChart from './components/chart'
import Selector from './components/selector'
import Datetime from './components/datepicker'
import BtcTableHeader from './components/table/table-header'
import BtcTableCell from './components/table/table-cell'
import commonmix from '@/utils/commonmix'
import { getLocalStore } from '@/utils/common'

export default {
  mixins: [commonmix],
  name: 'CnGlobalPorkExports',
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    BtcTable,
    BtcChart,
    Selector,
    Datetime,
    BtcTableCell,
    BtcTableHeader
  },
  data () {
    return {
      unit: '单位: 吨',
      list: [],
      selected: [],
      selectLength: 0,
      checkbox: ['总计'],
      chartData: null,
      chartQuery: {
        UserID: getLocalStore().UserID,
        LanguageCode: 'zh-CN',
        IsFirst: false,
        Origin: '西班牙',
        Destination: '总计',
        Type: '总计',
        Storage: '总计',
        Feeding: '总计',
        isEn: false
      },
      tableQuery: {
        UserID: getLocalStore().UserID,
        LanguageCode: 'zh-CN',
        IsFirst: false,
        Origin: '西班牙',
        Type: '总计',
        Storage: '总计',
        Feeding: '总计',
        StartDate: '',
        EndDate: '',
        isEn: false
      },
      type: [
        { label: '猪肉', value: '猪肉' },
        { label: '猪杂', value: '猪杂' }
      ],
      meatType: [
        { label: '总计', value: '总计' },
        { label: '带骨', value: '带骨' },
        { label: '去骨', value: '去骨' }
      ],
      storage: [
        { label: '总计', value: '总计' },
        { label: '冷冻', value: '冷冻' },
        { label: '冰鲜', value: '冰鲜' }
      ],
      header: [
        { label: '序号', icon: require('@/assets/image/data/order.png') },
        { label: '目的地', icon: require('@/assets/image/data/country.png') },
        { label: '出口量 (吨)', icon: require('@/assets/image/data/volumn1.png') },
        { label: '对比选择', icon: require('@/assets/image/data/contrast.png') }
      ],
      tempType: '',
      isDisable: false,
      startDate: '',
      endDate: '',
      isDisableOrigin: '美国|'
    }
  },
  watch: {
    'data.value' (nval, oval) {
      if (nval !== oval) {
        this.chartQuery.Origin = nval
        this.tableQuery.Origin = nval
        this.tableQuery.Type = '总计'
        this.tableQuery.Storage = '总计'
        this.tableQuery.Feeding = '总计'
        this.isDisable = false
        this.reset()
        this.getChartData((data) => {
          this.$refs.chart.refreshData(data)
        }, true)
        // this.getTableData()
      }
    }
  },
  computed: {
    isLogin () {
      return this.$store.getters.token == null || this.$store.getters.token === ''
    },
    isEnExpire () {
      return this.getUserProfile(this.$t('message._btcdata'))
    }
  },
  mounted () {
    // fix issue
    this.chartQuery.Origin = this.data.value
    this.tableQuery.Origin = this.data.value
    this.tableQuery.Type = '总计'
    this.tableQuery.Storage = '总计'
    this.tableQuery.Feeding = '总计'

    this.getChartData(null, true)
    // this.getTableData()
  },
  methods: {
    reset () {
      this.checkbox = ['总计']
      this.chartQuery.Destination = '总计'
    },
    getChartData (fn, isTable = false) {
      this.$store.dispatch('SetLoadingStatus', true)
      this.$store.dispatch('GetWebPorkNewDataTwoChart', this.chartQuery).then(() => {
        this.chartData = this.$store.getters.dataChart
        this.startDate = this.chartData.tableEndDate
        this.endDate = this.chartData.tableEndDate
        this.tableQuery.StartDate = this.endDate.replace('-', '')
        this.tableQuery.EndDate = this.endDate.replace('-', '')

        if (fn) {
          fn(this.chartData)
        }
        if (isTable) {
          this.getTableData()
        }
        this.$store.dispatch('SetLoadingStatus', false)
      }).catch((err) => {
        console.error(err)
        this.$store.dispatch('SetLoadingStatus', false)
      })
    },
    getTableData (refresh) {
      this.$store.dispatch('SetLoadingStatus', true)
      this.$store.dispatch('GetWebPorkNewDataTwoTable', this.tableQuery).then(() => {
        this.list = this.$store.getters.dataTable.tableData
        if (refresh) {
          this.checkbox = ['总计']
          this.chartQuery.Destination = '总计'
          this.getChartData((data) => {
            this.$refs.chart.refreshData(data)
          }, false)
        }
        this.$store.dispatch('SetLoadingStatus', false)
      }).catch((err) => {
        console.error(err)
        this.$store.dispatch('SetLoadingStatus', false)
      })
    },
    handleTabType (item) {
      if (item.value === '猪杂') {
        this.isDisable = true
        this.chartQuery.Type = item.value
        this.tableQuery.Type = item.value
      } else {
        this.isDisable = false
        this.chartQuery.Type = this.tempType
        this.tableQuery.Type = this.tempType
      }
      // this.getTableData(true)
      this.getChartData((data) => {
        this.$refs.chart.refreshData(data)
      }, true)
    },
    handleTabMeatType (item) {
      this.tempType = item.value
      this.chartQuery.Type = item.value
      this.tableQuery.Type = item.value
      // this.getTableData(true)
      this.getChartData((data) => {
        this.$refs.chart.refreshData(data)
      }, true)
    },
    handleTabStorage (item) {
      this.chartQuery.Storage = item.value
      this.tableQuery.Storage = item.value
      // this.getTableData(true)
      this.getChartData((data) => {
        this.$refs.chart.refreshData(data)
      }, true)
    },
    // handleTabFeeding (item) {
    //   this.chartQuery.Feeding = item.value
    //   this.tableQuery.Feeding = item.value
    //   // this.getTableData(true)
    //   this.getChartData((data) => {
    //     this.$refs.chart.refreshData(data)
    //   }, true)
    // },
    handleChangeCheckbox (e) {
      this.selected = e
      this.selectLength = e.length
      if (this.selectLength === 0) {
        return
      }
      if (this.selectLength > 5) {
        e.shift()
      }
      this.chartQuery.Destination = e.join('|')
      this.getChartData((data) => {
        this.$refs.chart.refreshData(data)
      }, false)
    },
    handleTapDate (date, type) {
      this.tableQuery[type] = date
      this.getTableData()
    },
    handleCharts () {
      if (this.$store.getters.token) {
        if (!this.isEnExpire) {
          this.openCnMembershipModal()
        }
      } else {
        this.openCnLoginModal()
      }
    },
    isLoginEnExpire () {
      if (this.$store.getters.token) {
        if (!this.isEnExpire) {
          this.openCnMembershipModal()
        }
      } else {
        this.openCnLoginModal()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.item-box {
  min-height: 420px;
  margin: 20px;
  padding-top: 20px;
  border-radius: 20px;
  background-color: #ffffff;
}

.selector-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.selector-left {
  display: flex;
  justify-content: space-between;
}
</style>
